import ProcessType from '@/enums/ProcessType';

const FOUNDING_TYPES = [
  ProcessType.foundingAg,
  ProcessType.foundingGmbh,
  ProcessType.foundingSole,
  ProcessType.foundingBranchAg,
  ProcessType.foundingBranchGmbh,
];

const MUTATION_TYPES = [
  ProcessType.mutationAg,
  ProcessType.mutationGmbh,
  ProcessType.mutationSole,
  ProcessType.mutationBranchAg,
  ProcessType.mutationBranchGmbh,
];

export function isFoundingProcess(type?: ProcessType): boolean {
  if (!type) return false;
  return FOUNDING_TYPES.includes(type);
}

export function isMutationProcess(type?: ProcessType): boolean {
  if (!type) return false;
  return MUTATION_TYPES.includes(type);
}

export function isSoleProcess(type?: ProcessType): boolean {
  return ProcessType.foundingSole === type || ProcessType.mutationSole === type;
}

export function isGmbhProcess(type?: ProcessType): boolean {
  return ProcessType.foundingGmbh === type || ProcessType.mutationGmbh === type;
}

export function isAgProcess(type?: ProcessType): boolean {
  return ProcessType.foundingAg === type || ProcessType.mutationAg === type;
}

export function isBranchAgProcess(type?: ProcessType): boolean {
  return ProcessType.mutationBranchAg === type || ProcessType.foundingBranchAg === type;
}

export function isBranchGmbhProcess(type?: ProcessType): boolean {
  return ProcessType.mutationBranchGmbh === type || ProcessType.foundingBranchGmbh === type;
}

export function isBranchProcess(type?: ProcessType): boolean {
  return isBranchAgProcess(type) || isBranchGmbhProcess(type);
}

export function isBranchMutationProcess(type?: ProcessType): boolean {
  return ProcessType.mutationBranchAg === type || ProcessType.mutationBranchGmbh === type;
}

export function isBranchFoundingProcess(type?: ProcessType): boolean {
  return ProcessType.foundingBranchAg === type || ProcessType.foundingBranchGmbh === type;
}

export function isGmbhMutationProcess(type?: ProcessType): boolean {
  return ProcessType.mutationGmbh === type || ProcessType.mutationBranchGmbh === type;
}

export function isAgMutationProcess(type?: ProcessType): boolean {
  return ProcessType.mutationAg === type || ProcessType.mutationBranchAg === type;
}

export function isHeadquarterAgProcess(type?: ProcessType): boolean {
  return ProcessType.mutationAg === type || ProcessType.foundingAg === type;
}

export function isHeadquarterGmbhProcess(type?: ProcessType): boolean {
  return ProcessType.mutationGmbh === type || ProcessType.foundingGmbh === type;
}
