import useCachedLocale from '@/ts/VueCompositions/UseCachedLocale';
export default function useProcessOpen(processId) {
  // redirecting to current correct status of the process (process_open route)
  var _useCachedLocale = useCachedLocale(),
    getCachedLocale = _useCachedLocale.getCachedLocale;
  var isRedirecting = ref(false);
  function redirectToProcessOpen(params) {
    var searchParams = new URLSearchParams(params !== null && params !== void 0 ? params : {});
    var query = searchParams ? "?".concat(searchParams.toString()) : '';
    window.location.href = "/".concat(getCachedLocale(), "/process/").concat(unref(processId), "/open").concat(query);
    isRedirecting.value = true;
  }
  return {
    isRedirecting: isRedirecting,
    redirectToProcessOpen: redirectToProcessOpen
  };
}