/* eslint-disable max-classes-per-file */
import type { ApiResponse } from '@/js/composables/useQueryFn';
import { Violation } from '@/interfaces/Violation';

export type ApiError<
  T = Record<string, never>,
  TType extends string = string,
  TContext extends string = string,
  TID extends string = string
> = T &
  ApiResponse<
    {
      'hydra:title': string;
      'hydra:description': string;
    },
    TType,
    TContext,
    TID
  >;

export type ApiErrorUnprocessableEntity = ApiError<
  {
    violations: Array<Violation>;
  },
  'ConstraintViolationList',
  'ConstraintViolationList',
  never
>;

export class FetchError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);

    this.name = 'FetchError';
    this.response = response;
  }

  static async fromResponse(response: Response): Promise<FetchError> {
    const json = await response.json();
    if (
      typeof json === 'object' &&
      json !== null &&
      typeof json.code === 'number' &&
      typeof json.key === 'string'
    ) {
      // eslint-disable-next-line no-use-before-define
      return new CustomBadRequestError(response, json);
    }

    if (response.status === 422) {
      // eslint-disable-next-line no-use-before-define
      return new UnprocessableEntityError(response, json);
    }

    return new FetchError(response.statusText, response);
  }
}

export class UnprocessableEntityError extends FetchError {
  violations: ApiErrorUnprocessableEntity['violations'] = [];

  allErrors: Record<string, string[]> = {};

  errors: Record<string, string> = {};

  constructor(response: Response, json: ApiErrorUnprocessableEntity) {
    super('Unprocessable Entity', response);

    this.name = 'UnprocessableEntityError';

    this.parseErrors(json);
  }

  protected parseErrors(json: ApiErrorUnprocessableEntity): void {
    this.violations = json.violations;

    this.allErrors = json.violations.reduce((acc, { propertyPath, message }) => {
      if (!Array.isArray(acc[propertyPath])) {
        acc[propertyPath] = [];
      }

      acc[propertyPath].push(message);

      return acc;
    }, {} as Record<string, string[]>);

    this.errors = Object.fromEntries(
      Object.entries(this.allErrors).map(([key, messages]) => [key, messages[0]])
    );
  }
}

interface CustomBadRequestErrorJsonBody {
  code: number;
  key: string;
}

export class CustomBadRequestError extends FetchError {
  key: string;

  code: number;

  constructor(response: Response, json: CustomBadRequestErrorJsonBody) {
    super('CustomBadRequestError', response);
    this.name = 'CustomBadRequestError';
    this.key = json.key;
    this.code = json.code;
  }
}

export type AnyFetchError =
  | typeof FetchError
  | typeof UnprocessableEntityError
  | typeof CustomBadRequestError;
