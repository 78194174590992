import { createNestedErrors } from '@/js/utils/validation';
// This returns the validation errors as nested object
export function useValidationErrors(error) {
  var fieldErrors = computed(function () {
    var unrefedError = unref(error);
    if (unrefedError instanceof UnprocessableEntityError) {
      return createNestedErrors(unrefedError.violations);
    }
    return undefined;
  });
  var hasFieldErrors = computed(function () {
    var _fieldErrors$value;
    return Object.keys((_fieldErrors$value = fieldErrors.value) !== null && _fieldErrors$value !== void 0 ? _fieldErrors$value : {}).length > 0;
  });
  return {
    fieldErrors: fieldErrors,
    hasFieldErrors: hasFieldErrors
  };
}