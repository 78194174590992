import axios from 'axios';
import { getLocale } from '@/js/frontend/helpers';

export async function useLocallyCachedBackendData(
  url: string,
  dataDependsOnLocale: boolean,
  cacheDurationInSeconds: number = 60 * 60 * 24
) {
  const localeAppend = dataDependsOnLocale ? `:${getLocale()}` : '';
  const cacheKey = `backend-cache:${url}:data${localeAppend}`;
  const cacheTimestampKey = `backend-cache:${url}:timestamp${localeAppend}`;
  const timestamp = localStorage.getItem(cacheTimestampKey);

  if (!timestamp || Date.now() - parseInt(timestamp, 10) > cacheDurationInSeconds * 1000) {
    const response = await axios.get(url);
    localStorage.setItem(cacheKey, JSON.stringify(response.data));
    localStorage.setItem(cacheTimestampKey, Date.now().toString());
  }
  const result = localStorage.getItem(cacheKey);
  return result !== null ? JSON.parse(result) : null;
}
